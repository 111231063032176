import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home'; // Original content of the root page, now used for About
import GameList from './GameList'; // The new root page
import Rodman from './Rodman';
import EventDetails from './EventDetails'; // Event details page
import Header from './Header';
import './App.css';
import ReactGA from 'react-ga';

ReactGA.initialize('G-ELYCEFYTSR');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<GameList />} /> {/* Games is now the root page */}
                        <Route path="/about" element={<Home />} /> {/* Home used for About */}
                        <Route path="/rodman" element={<Rodman />} />
                        <Route path="/event/:id" element={<EventDetails />} /> {/* Event Details route */}
                        {/* Deprecated players route removed */}
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
