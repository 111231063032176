import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { formatLeagueName } from './utils'; // Importing from utils
import './EventDetails.css';

const EventDetails = () => {
    const location = useLocation();
    const { eventStats, game, prediction } = location.state || {};

    // Scroll to the top of the page on navigation
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!eventStats || !eventStats.eventstats || eventStats.eventstats.length === 0) {
        // Graceful handling for missing stats
        return (
            <div className="event-details">
                <h1 className="event-details-title">Event Details</h1>
                <div className="no-details">
                    <p>Event details are currently unavailable.</p>
                    <p>Stay tuned for updates!</p>
                </div>
            </div>
        );
    }

    const possessionStat = eventStats.eventstats.find((stat) => stat.strStat === 'Ball Possession');
    const stats = eventStats.eventstats.filter((stat) => stat.strStat !== 'Ball Possession');

    const getWinnerClass = (value, opposingValue) => {
        const valueFloat = parseFloat(value);
        const opposingValueFloat = parseFloat(opposingValue);
        if (valueFloat > opposingValueFloat) return 'highlight';
        return ''; // No highlight for the losing team
    };

    return (
        <div className="event-details">
            <h1 className="event-details-title">{`${game.home_team} vs ${game.away_team}`}</h1>

            {/* Centered Game Card */}
            <div className="centered-card">
                <div className="game-card">
                    <div className="game-info">
                        <span className="team">
                            <img
                                src={game.home_team_badge}
                                alt={`${game.home_team} Badge`}
                                className="team-badge"
                            />
                            <strong>{game.home_team}</strong>
                            <span className="team-score">
                                {game.home_score !== undefined ? game.home_score : '?'}
                            </span>
                        </span>
                        {prediction && (
                            <div className="win-prob-container">
                                <span className="prob-label">Win Prob.</span>
                                <span
                                    className={`win-prob home ${
                                        prediction.home_win_prob > prediction.away_win_prob
                                            ? 'favored'
                                            : ''
                                    }`}
                                >
                                    {prediction.home_win_prob !== undefined
                                        ? `${Math.round(prediction.home_win_prob * 100)}%`
                                        : 'N/A'}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="game-divider"></div>
                    <div className="game-info">
                        <span className="team">
                            <img
                                src={game.away_team_badge}
                                alt={`${game.away_team} Badge`}
                                className="team-badge"
                            />
                            <strong>{game.away_team}</strong>
                            <span className="team-score">
                                {game.away_score !== undefined ? game.away_score : '?'}
                            </span>
                        </span>
                        {prediction && (
                            <div className="win-prob-container">
                                <span
                                    className={`win-prob away ${
                                        prediction.away_win_prob > prediction.home_win_prob
                                            ? 'favored'
                                            : ''
                                    }`}
                                >
                                    {prediction.away_win_prob !== undefined
                                        ? `${Math.round(prediction.away_win_prob * 100)}%`
                                        : 'N/A'}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="game-details">
                        <span className="league-name league-left">
                            {formatLeagueName(game.league)} {/* Using utils */}
                        </span>
                        {prediction && (
                            <span className="draw-prob">
                                Draw:{' '}
                                {prediction.draw_prob !== undefined
                                    ? `${Math.round(prediction.draw_prob * 100)}%`
                                    : 'N/A'}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            {/* Event Stats */}
            <div className="stats-card">
                {/* Ball Possession */}
                {possessionStat && (
                    <div className="possession-container">
                        <h2 className="possession-label">Ball Possession</h2>
                        <div className="possession-row">
                            <div className="possession-bar">
                                <div
                                    className="bar home"
                                    style={{ width: `${possessionStat.intHome}%` }}
                                >
                                    <span className="possession-percentage">
                                        {possessionStat.intHome}%
                                    </span>
                                </div>
                                <div
                                    className="bar away"
                                    style={{ width: `${possessionStat.intAway}%` }}
                                >
                                    <span className="possession-percentage">
                                        {possessionStat.intAway}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Other Stats */}
                <div className="stats-list">
                    {stats.map((stat) => (
                        <div key={stat.idStatistic} className="stat-row">
                            <span
                                className={`team-value home ${getWinnerClass(
                                    stat.intHome,
                                    stat.intAway
                                )}`}
                            >
                                {stat.intHome}
                            </span>
                            <span className="stat-name">{stat.strStat}</span>
                            <span
                                className={`team-value away ${getWinnerClass(
                                    stat.intAway,
                                    stat.intHome
                                )}`}
                            >
                                {stat.intAway}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EventDetails;
